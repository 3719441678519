import React from 'react';
import { Person, WebSite } from 'schema-dts';
import { JsonLd } from 'react-schemaorg';

export function SDThisSite() {
  return (
    <JsonLd<WebSite>
      item={{
        '@context': 'https://schema.org',
        '@type': 'WebSite',
        'url': 'https://aditimohanty.com',
        'name': 'Aditi Mohanty | Front-End Engineer, Bangalore, India',
        'author': {
          '@type': 'Person',
          'name': 'Aditi Mohanty',
        },
      }}
    />
  );
}

export function SDAditiMohanty() {
  return (
    <JsonLd<Person>
      item={{
        '@context': 'https://schema.org',
        '@type': 'Person',
        'name': 'Aditi Mohanty',
        'givenName': 'Aditi',
        'additionalName': 'Anomita',
        'familyName': 'Mohanty',
        'alternateName': 'Rhea Aditi Mohanty',
        'gender': 'Female',
        'birthDate': '1993-11-15',
        'url': 'https://aditimohanty.com',
        'worksFor': {
          '@type': 'Organization',
          'url': 'https://www.atlassian.com/',
        },
        'jobTitle': 'Software Engineer III',
        'workLocation': {
          '@type': 'City',
          'name': 'Bangalore',
        },
        'sameAs': [
          'https://twitter.com/rheaditi',
          'https://github.com/rheaditi',
          'https://www.linkedin.com/in/aditimohanty',
          'https://medium.com/@rheaditi',
          'https://angel.co/aditimohanty',
          'https://codepen.io/rheaditi',
        ],
        'alumniOf': {
          '@type': 'CollegeOrUniversity',
          'name': 'PES Institute of Technology, Bangalore South Campus',
          'sameAs': 'https://pesitsouth.pes.edu',
        },
        'knowsAbout': ['Software Engineering', 'Computer Science', 'Front-end Web Development'],
      }}
    />
  );
}
