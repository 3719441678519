import React, { PropsWithChildren } from 'react';

import { Helmet } from 'react-helmet';

import { trimTrailingSlash } from '@utils/url';
import { site, me } from '@data/static';
import { useStaticQuery, graphql } from 'gatsby';

interface SEOProps {
  title?: typeof site['title'];
  description?: string;
  pathname?: string;
}

const SEO: React.FC<PropsWithChildren<SEOProps>> = (props: PropsWithChildren<SEOProps>) => {
  const {
    site: {
      siteMetadata: { siteUrl },
    },
  } = useStaticQuery(graphql`
    query GetSiteUrlSEO {
      site {
        siteMetadata {
          siteUrl
        }
      }
    }
  `);
  const { title, pathname, description, children } = props;
  const url = `${trimTrailingSlash(siteUrl)}${pathname}`;
  const link = [
    pathname && {
      rel: 'canonical',
      href: url,
    },
  ].filter(Boolean) as React.DetailedHTMLProps<React.LinkHTMLAttributes<HTMLLinkElement>, HTMLLinkElement>[];

  const actualTitle = title ? `${title} | ${site.title}` : site.title;
  const actualDescription = description || site.description;

  const meta = [
    // og misc
    {
      property: `og:type`,
      content: `website`,
    },
    {
      property: `og:locale`,
      content: `en_IN`,
    },
    {
      property: `og:locale:alternate`,
      content: `en_US`,
    },
    {
      property: `og:url`,
      content: url,
    },

    // title
    {
      name: `og:title`,
      content: actualTitle,
    },
    {
      name: `twitter:title`,
      content: actualTitle,
    },

    // description
    {
      name: `description`,
      content: actualDescription,
    },
    {
      property: `og:description`,
      content: actualDescription,
    },
    {
      name: `twitter:description`,
      content: actualDescription,
    },

    {
      name: `twitter:creator`,
      content: me.name.usual,
    },

    // twitter
    {
      name: `twitter:card`,
      content: `summary`,
    },
    {
      name: `twitter:site`,
      content: me.social.twitter.handle,
    },
    {
      name: `twitter:creator`,
      content: me.social.twitter.handle,
    },
  ].filter(Boolean);
  return (
    <>
      <Helmet title={title} titleTemplate={`%s | ${site.title}`} defaultTitle={site.title} link={link} meta={meta} />
      {children}
    </>
  );
};

SEO.defaultProps = {
  title: site.title,
};

export default SEO;
